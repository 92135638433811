import { Controller } from "@hotwired/stimulus";
import Plyr from "plyr";

export default class extends Controller {
  static targets = ["video", "wrapper", "spinner", "overlays"]

  initialize() {
    this.videoTarget.addEventListener("loadedmetadata", () => {
      // Video metadata (like duration) has been loaded
      this.spinnerTarget.classList.add("hidden")
      this.videoTarget.classList.remove("hidden")
      if (this.hasOverlaysTarget) {
        this.overlaysTarget.classList.remove("hidden")
      }
      this.initializePlyr()
    })
  }

  initializePlyr() {
    const isWideScreen = window.matchMedia("(min-width: 912px)").matches;

    this.player = new Plyr(this.videoTarget, {
      controls: ["play-large", "play", "progress", "current-time", "mute", "volume", "captions", "settings", "pip", "airplay", "fullscreen", "download"],
      download: true,
      fullscreen: { 
        enabled: isWideScreen,
        fallback: true,
        container: "#container"
      }
    })

    // Move overlay into Plyr's container after initialization
    const plyrContainer = this.videoTarget.closest(".plyr")
    if (plyrContainer && this.hasOverlaysTarget) {
      plyrContainer.appendChild(this.overlaysTarget)
    }

    // Handle fullscreen events
    this.player.on('enterfullscreen', () => {
      this.wrapperTarget.classList.add("h-screen")
      this.videoTarget.classList.add("max-h-screen", "max-w-full")
    })

    this.player.on('exitfullscreen', () => {
        this.wrapperTarget.classList.remove("h-screen")
        this.videoTarget.classList.remove("max-h-screen", "max-w-full")
    })
  }

  disconnect() {
    if (this.player) {
      this.player.destroy()
    }
  }
}
